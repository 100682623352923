<template>
  <h4>
    ReportPlusList
  </h4>
</template>

<script>
import SelectCreateInteractor from "@/business/report-plus-list/select-create";
import SelectReportPlusViewInteractor from "@/business/report-plus-list/select-report-plus-view";
import SelectReportPlusEditInteractor from "@/business/report-plus-list/select-report-plus-edit";
import InitReportPlusListInteractor from "@/business/report-plus-list/init-report-plus-list";
import ChangeFilterInteractor from "@/business/report-plus-list/change-filter";
import ReportPlusListScreenController from "@/adapters/controllers/screen-report-plus-list";

export default {
  screen_name: "report-plus-list",
  data() {
    return {
      controller: null,
      interactors: {
        selectCreate: null,
        selectReportPlusView: null,
        selectReportPlusEdit: null,
        initReportPlusList: null,
        changeFilter: null
      },
      data: null
    };
  },
  beforeMount() {
    this.controller = this.$injector.get(ReportPlusListScreenController);

    //{ INTERACTORS
    this.interactors.changeFilter = this.$injector.get(ChangeFilterInteractor);
    this.interactors.initReportPlusList = this.$injector.get(
      InitReportPlusListInteractor
    );
    this.interactors.selectReportPlusEdit = this.$injector.get(
      SelectReportPlusEditInteractor
    );
    this.interactors.selectReportPlusView = this.$injector.get(
      SelectReportPlusViewInteractor
    );
    this.interactors.selectCreate = this.$injector.get(SelectCreateInteractor);
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
  },
  mounted() {},
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    }
  }
};
</script>

<style lang="scss"></style>
